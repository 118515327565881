<template>
  <sdPageHeader title="Gift and Prize">
  </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-row :gutter="15">
        <a-col :xs="24">
          <TopToolBox>
            <a-row :gutter="15" class="justify-content-between">
              <a-col :lg="6" :xs="24">
                <div class="table-search-box">
                  <SearchInputStyled
                    style="width: 100%;"
                    class="certain-category-search"
                    placeholder="Search prices"
                    @search="onSearching"
                  >
                    <a-input>
                      <template #suffix>
                        <sdFeatherIcons type="search"/>
                      </template>
                    </a-input>
                  </SearchInputStyled>
                </div>
              </a-col>
              <a-col :xxl="14" :lg="16" :xs="24">
                <div class="page-header-actions pull-right">
                    <sdButton @click="navigateTo('setup')" size="small" type="primary">
                        <sdFeatherIcons type="plus" size="14" />
                        Create Price 
                    </sdButton>
                </div>
              </a-col>
            </a-row>
          </TopToolBox>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col :md="24">
          <TableWrapper class="table-order table-responsive">
            <a-table
              rowKey="id"
              :columns="fields"
              :dataSource="prices"
              @change="handleTableChange"
              :pagination="{ pageSize: perPage, showSizeChanger: false, total: totalData}"
            >
            <template #quantity="{record}">
              <div v-if="record.quantity != '' && record.quantity != null">
                  {{record.quantity}}
              </div>
              <div v-else>0</div>
            </template>
            <template #winners="{record}">
              <div v-if="record.winners != '' && record.winners != null">
                <a @click="navigateTo(`/${initial}/prizes/winner-list/${record.encrypt_id}`)">{{ record.winners }}</a>
              </div>
              <div v-else>0</div>
            </template>
            <template #action="{record}">
              <div class="table-actions">
                <sdButton @click="prizeQuntityModal(record)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="plus-circle" size="16" />
                </sdButton>
                <sdButton @click="navigateTo(`setup/${record.encrypt_id}`)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>
                <sdButton  @click="onRemovePress(record.encrypt_id)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="trash-2" size="16" />
                </sdButton>
              </div>
            </template>
            </a-table>
          </TableWrapper>
        </a-col>
      </a-row>
    </sdCards>
    <a-modal
      v-model:visible="deleteConfirmVisible"
      title="Price remove confirmation"
      :confirm-loading="isDeleting"
      :centered="true"
      ok-text="Remove"
      cancel-text="Cancel"
      @ok="handleDeleteModal"
      :ok-button-props="okButtonProps"
    >
      <p>Are you sure you want to delete this price ?</p>
    </a-modal>

    <a-modal
      v-model:visible="addQuantityVisible"
      :title="`Prize : ${prizeName}`"
      :centered="true"
      ok-text="Save"
      cancel-text="Cancel"
      @cancel="cancelQuntityModal"
      @ok="handleQuantityAddModal"
      :ok-button-props="okButtonProps"
    >
      <a-form :hideRequiredMark="false" ref="formRef" :model="form" :rules="formRules">
        <a-form-item name="prizeQuantity" label="Add Quantity">
                <a-input
                  type="number"
                   v-model:value="form.prizeQuantity"
                  placeholder="Enter Quantity"
                />
        </a-form-item>
      </a-form>
    </a-modal>


  </Main>
</template>
<script>
import { TopToolBox, SearchInputStyled } from './prize';
import Notification from "@/services/Notification";

import { API } from "@/services/api";
import { Main, TableWrapper } from '../../styled';

import { theme } from '@/config/theme/themeVariables';

export default {
  name: "Packages",
  components: { TopToolBox, Main, TableWrapper, SearchInputStyled },
  computed: {
    initial: function() {
      return this.$store.getters.initial;
    },
    formRef: function() {
      return this.$refs.formRef;
    }
  },
  data() {
    return {
      search: "",
      status: "",
      perPage: 10,
      totalData: 0,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      isSearch: false,
      fetching: false,
      isDeleting: false,
      deleteConfirmVisible: false,
      addQuantityVisible: false,
      deleteId: '',
      prizeId: '',
      prizeName: '',
      form : {
        prizeQuantity: '',
      },
      formRules: {
          prizeQuantity: [{
            required: true, trigger: 'blur',
            message: 'Please enter quantity',
          },
          {
            type: 'number', trigger: 'blur',transform:(val) => Number(val),
            message: 'Please enter number',
          },
        ],
      },
      prices: [],
      fields: [
        { title: 'Id', dataIndex: 'id', key: 'id',sorter : true,sort_index:0},
        { title: 'Name', dataIndex: 'name', key: 'name',sorter : true,sort_index:1},
        { title: 'Value', dataIndex: 'value',sorter : true,sort_index:2},
        { title: 'Quantity', dataIndex: 'quantity',sorter : true,
            slots: { customRender: 'quantity' },sort_index:3
        },
        { title: 'Winners', dataIndex: 'winners',
            slots: { customRender: 'winners' },
        },
        { title: 'Action', dataIndex: 'action', key: 'action',
          slots: { customRender: 'action' },
        },
      ],
      okButtonProps: {
        style: {
          backgroundColor: theme['primary-color'],
          borderWidth: 0,
        },
      },
    };
  },
  methods: {
    navigateTo: function (path = "") {
      if (path) {
        this.$router.push({path});
      }
    },
    async getPrizes() {
      try {
        this.fetching = true;
        const params = {
          page: this.currentPage,
          limit: this.perPage,
          search: this.search,
          status: this.status,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
        };
        const datRes = await API.getPrizes({ params });
        const { data: sData, status, message } = datRes.data;
        if (!status && message) {
          Notification.show(message, {type: "error",title: "Error"});
        } else {
          this.prices = sData.prizes;
          this.totalData = sData.total_rows;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, {type: "error",title: "Error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    onSearching(text) {
      this.search = text;
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getPrizes();
      }, 500);
    },
    handleTableChange(page,filters, sorter) {
      if (this.currentPage != page.current) {
        this.currentPage = page.current;
        setTimeout(() => {
          this.getPrizes();
        }, 300);
      }
      if(typeof sorter.column.sort_index != "undefined" && sorter.order != "" ) {
        this.sortField = sorter.column.sort_index;
        this.sortOrder = "asc";
        if(sorter.order != "" && sorter.order == "descend") {
          this.sortOrder = "desc";
        }
        this.getPrizes();
      }
    },
    async handleDeleteModal() {
      try {
        const delRes = await API.deletePrizes({id: this.deleteId});
        const {status, message} = delRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.getPrizes();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.deleteConfirmVisible = false;
      }
    },
    async handleQuantityAddModal() {
      try {
        await this.formRef.validate();
        const updateRes = await API.updatePrizeQuantity({id: this.prizeId,quantity: this.form.prizeQuantity});
        const {status, message} = updateRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          this.addQuantityVisible = false;
          this.form.prizeQuantity = "";
          this.getPrizes();
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    onRemovePress(id) {
      this.deleteId = id;
      this.deleteConfirmVisible = true;
    },
    prizeQuntityModal(val) {
      this.prizeId    = val.encrypt_id;
      this.prizeName  =  val.name;
      this.addQuantityVisible = true;
    },
    cancelQuntityModal() {
      this.formRef.clearValidate()
      this.form.prizeQuantity = "";
    },
  },
  created() {
    this.getPrizes();
  }
}

</script>
